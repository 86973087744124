<template>
	<div class="excursion pb-10 pt-3">
		<v-container fluid class="excursion-1">
			<v-container class="vuetify-container">
				<h1 class="section-title playfair w-100 dark-text mt-0 mb-3 mb-sm-0" data-aos="fade-left" data-aos-duration="900">{{ item.city }}</h1>
					<div class="home-card mb-0 mb-md-5 mb-lg-12 flex-center" data-aos="fade-up" data-aos-duration="1400">
						<v-row class="w-100 ma-0">
							<v-col cols="12" md="6" class="flex-center flex-column px-7 px-lg-12 py-12 py-md-8">
								<h2 class="w-100 dark-text font-weight-light px-0 px-md-5 px-lg-12 mb-5">
									{{ $t('welcomeTo') }} <span class="font-weight-bold">{{ item.city }}</span>!
								</h2>
								<p class="w-100 text--secondary px-0 px-md-5 px-lg-12">
									{{ item.desc }}
								</p>

								<p class="w-100 text--disabled px-0 px-md-5 px-lg-12">
									{{ $t('udaljenost') }}: {{ item.distance }}km
								</p>
								<div class="w-100 px-0 px-md-5 px-lg-12">
									<v-btn depressed outlined tile height="60" color="#212121" dark class="font-weight-bold mt-10 px-5" :href="item.link" target="_blank">{{ $t('learnMore') }}</v-btn>
								</div>
							</v-col>
							<v-col cols="12" md="6" class="flex-center flex-column pa-0">
								<img :src="getImage(item.image)" :alt="item.city" class="home-2-image">
							</v-col>
						</v-row>
					</div>
			</v-container>
		</v-container>
	</div>
</template>
<script>
export default {
	name: 'Excursion',
	data: () => ({
		//
	}),
	computed: {
		item: function() {
			return this.$store.getters.excursions.find(item => item.slug === this.$route.params.slug);
		}
	},
	methods: {
		getImage(image) {
			return require('../assets/img/excursions/covers/' + image);
		}
	}
}
</script>
<style lang="scss">
.excursion-1 {
	background-color: #eee;
}
.home-card {
	background-color: #fff;
	.row {
		min-height: 60vh;
	}
}
.home-2-image {
	max-width: 100%;
	object-fit: cover;
	max-height: 100%;
	min-height: 60vh;
}
</style>